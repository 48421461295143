/*#region SassySocialShareBtn */
/**
 * @private object jQuery btn = button
 * @private array excludeBtnByTitle = list of exclude button by title
 * @private string startSentence = start of sentence
 * @private string sentence complet
 */
 class SassySocialShareBtn {

    /*#region Properties */
    #btn = null;
    #excludeBtnByTitle = [];
    #startSentence = "Partager";
    #sentence = "";
    /*#endregion*/

    /*#region Constructor */
    /**
     * @private object jQuery btn = button
     */
    constructor(btn) {
        this.#btn = btn;
    }
    /*#endregion*/

    /*#region Accessors */
    /**
     * @array list of title btn to exclude
     */
    setExcludeBtnByTitle(exlusionTitleList) {
        if (typeof exlusionTitleList == "array") {
            if (exlusionTitleList.length) {
                this.#excludeBtnByTitle = exlusionTitleList;
            }
        }
    }
    /*#endregion*/

    /*#region Dom Accessors */
    #getBtn() {
        return jQuery(this.#btn);
    }

    #addBtnLabel() {
        this.#getBtn().append(`<span class="sr-only">${this.#sentence}</span>`);
    }

    #setRoleBtnToA() {
        if (this.#getBtn().is("a")) {
            this.#getBtn().attr("role", "button");
        }
    }

    #getTitleBtn() {
        return this.#getBtn().attr("title");
    }

    #setTitleBtn() {
        this.#getBtn().attr("title", `${this.#sentence}`);
    }
    /*#endregion*/

    /*#region Mehods */
    patchBtn() {
        this.#setRoleBtnToA();
        const title = this.#getTitleBtn();

        if (title != null && title != undefined && title != "") {
            if (!this.#excludeBtnByTitle.includes(title)) {
                this.#preparedWhrite();
                this.#addBtnLabel();
                this.#setTitleBtn();
            }
        }
    }

    #preparedWhrite() {

        switch (this.#getTitleBtn()) {
            case "Email":
                this.#sentence = this.#startSentence + " par " + this.#getTitleBtn();
                break;
            case "Print":
                this.#sentence = "Imprimer";
                break;
            default:
                this.#sentence = this.#startSentence + " sur " + this.#getTitleBtn();
        }
    }
    /*#endregion*/
}
/*#endregion*/

/*#region SassySocialSharePatch */
/** 
 * @private string selectorContainerListBtn = for select the container of buttons
 * @private string selectorBtn = for select tag of button
 * @array list of title btn to exclude
 */
export default class SassySocialSharePatch {

    /*#region Properties */
    #selectorContainerListBtn = "";
    #selectorBtn = "";
    #exlusionTitleList = [];
    /*#endregion*/

    /*#region Constructor */
    constructor(selectorContainerListBtn, selectorBtn, exlusionTitleList = []) {
        this.#selectorContainerListBtn = selectorContainerListBtn;
        this.#selectorBtn = selectorBtn;
        this.#exlusionTitleList = exlusionTitleList;
        this.#init();
    }
    /*#endregion*/

    /*#region Access */
    #getListBtns() {
        return jQuery(this.#selectorContainerListBtn).find(this.#selectorBtn).toArray();
    }
    /*#endregion*/

    /*#region Init */
    #init() {
        this.#getListBtns().forEach(btn => {
            const sassySocialShareBtn = new SassySocialShareBtn(btn);
            sassySocialShareBtn.setExcludeBtnByTitle(this.#exlusionTitleList);
            sassySocialShareBtn.patchBtn();
        });
    }
    /*#endregion*/

}
/*#endregion*/

