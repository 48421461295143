import SassySocialSharePatch from './jAko/sassySocialShare/SassySocialSharePatch.js';

/**
 * @string selectorContainerListBtn = for select the container of buttons
 * @string selectorBtn = for select tag of button
 * @array list of title btn to exclude
 */
new SassySocialSharePatch(
    ".heateor_sss_sharing_container .heateor_sss_sharing_ul",
    "a",
    ["More"]
);

/*#region access pdf file download */
jQuery(function() {
    jQuery('a[href$=".pdf"], a[href$=".doc"], a[href$=".mp3"], a[href$=".m4u"], a[href$=".zip"], a[href$=".mp4"]').each(function(i, obj){
    var link = jQuery(obj);
    var bits = this.href.split('.');
    var typeOfFile = bits[bits.length -1];

    // Test d'utilisation du bloc "Fichier" de Gutenberg
    if(link.attr("download") == ""){
        link.removeAttr("download"); 
        link.attr("target", "_blank");
    } 

    // Test si le libellé contient déjà le format et son poids
    if(link.text().indexOf(" (" + typeOfFile.toUpperCase()) == -1){
        var request;
        request = jQuery.ajax({
            type: "HEAD",
            url: link.attr("href"),
            success: function() {
                var length = request.getResponseHeader("Content-Length");
                if (!isNaN(parseInt(length))) {
                    var fileSize = readablizeBytes(length);
                    link.text(link.text() + ' (' + typeOfFile.toUpperCase() + ' - ' + fileSize + ')');
                    //link.attr("title", "PDF, "+ fileSize  +", opens in a new window");

                    if(link.attr("target") == "_blank"){
                        link.attr("title",jQuery.trim(jQuery.trim(link.text()) + " (ouvrir dans nouvelle fenêtre)"));
                        //link.attr("data-original-title",jQuery.trim(jQuery.trim(link.text()) + " (nouvelle fenêtre)"));
                        //link.attr("aria-label",jQuery.trim(jQuery.trim(link.text()) + " (nouvelle fenêtre)"));
                    }else{
                        link.attr("title",jQuery.trim(jQuery.trim(link.text())));
                        //link.attr("data-original-title",jQuery.trim(jQuery.trim(link.text())));
                        //link.attr("aria-label",jQuery.trim(jQuery.trim(link.text())));
                    };

                }
            }
        })
    }
    })
});

// From http://web.elctech.com/2009/01/06/convert-filesize-bytes-to-readable-string-in-javascript/
function readablizeBytes(bytes) {
    var s = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po'];
    var e = Math.floor(Math.log(bytes)/Math.log(1024));
    return (bytes/Math.pow(1024, Math.floor(e))).toFixed(2)+" "+s[e];
}

// Ajout du libelle "nouvelle fenêtre" pour les liens target blank
jQuery("a[target='_blank']").each(function( index ) {
    if(jQuery(this).attr("title") == undefined) jQuery(this).attr("title",jQuery.trim(jQuery.trim(jQuery(this).text()) + " (ouvrir dans nouvelle fenêtre)"));
    //jQuery(this).attr("aria-label",jQuery.trim(jQuery.trim(jQuery(this).text()) + " (nouvelle fenêtre)"));
});
/*#endregion*/